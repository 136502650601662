import React from 'react';
import styled from 'styled-components';
import { FaHeadset } from 'react-icons/fa'; // Import the desired icon
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactContainer = styled.div`
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  display: flex;
  flex-direction: row; /* Change the direction to row */
  align-items: center;
  justify-content: space-between; /* Adjust spacing */
  padding: 100px; /* Adjust padding */
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the form content to the left */
`;

const Text = styled.h3`
  overflow: hidden;
  text-decoration: underline;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px; /* Adjust margin */
  padding: 100px;
  font-size: 15vw; /* Default size for larger screens */

  @media screen and (max-width: 1000px) {
    font-size: 30vw; /* Size for screens smaller than 1000px width */
  }
`;

const FormSubmitButton = styled.button`
  outline: none;
  margin: 0;
  border: none;
  box-shadow: none;
  color: white;
  border-bottom: solid;
  border-color: black;
  background: rgb(121, 9, 9);
//   background: linear-gradient(90deg, rgba(121, 9, 9, 1) 50%, rgba(169, 169, 169) 100%);
  padding: 10px;
  cursor: pointer;
`;

function Contact() {
  function openUVdeskSupport() {
    
    window.open('https://support.reddawgtech.com', '_blank');
  }

  return (
    <ContactContainer id="contact">
      <IconContainer>
        <FaHeadset /> {/* Use the desired size for the icon */}
      </IconContainer>
      <FormContainer>
        <Text>Contact Our Support Team</Text>
        <p>
        If you have any inquiries, need assistance with our services, or simply have questions about how we can support you, please don't hesitate to reach out to our dedicated support team. We're here to help and ensure you have a seamless experience with our offerings. Click the button below to connect with our team.
        </p>
        <FormSubmitButton onClick={openUVdeskSupport}>Contact</FormSubmitButton>
      </FormContainer>
    </ContactContainer>
  );
}

export default Contact;
