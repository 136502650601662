import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ReviewSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
`;

const ReviewTitle = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  color: black;
  margin-top: 20px;
`;

const Review = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
`;

const ReviewText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StarRating = styled.div`
  font-size: 24px;
  color: #ffbf00;
`;

const PlaceholderReview = styled.div`
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
`;

const ReviewHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: black;
`;

const Username = styled.span`
  font-weight: bold;
`;

const DatePosted = styled.span`
  margin-left: 10px;
  color: #888;
`;

const CreateReviewLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover{
    color: black;
  }
`;

function Yelp() {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    axios.get("http://localhost:3004/yelp-proxy")
      .then(response => {
        setReviews(response.data.reviews);
        calculateAverageRating(response.data.reviews);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const calculateAverageRating = reviews => {
    if (reviews.length === 0) {
      setAverageRating(0);
      return;
    }

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    const avgRating = totalRating / reviews.length;
    setAverageRating(avgRating);
  };

  return (
    <ReviewSection id="reviews">
      <ReviewTitle>Reviews</ReviewTitle>
      <ReviewHeader>
        <p>Average Rating: {averageRating.toFixed(2)} stars</p>
      </ReviewHeader>
      {reviews.length > 0 ? (
        reviews.map(review => (
          <Review key={review.id}>
            <Username>{review.user.name || 'Anonymous'}</Username>
            <DatePosted>{new Date(review.time_created).toLocaleDateString()}</DatePosted>
            <ReviewText>{review.text}</ReviewText>
            <StarRating>{'★'.repeat(review.rating)}</StarRating>
          </Review>
        ))
      ) : (
        <PlaceholderReview>
          No reviews available at the moment.
        </PlaceholderReview>
      )}
      <CreateReviewLink href="">
        Write a Review
      </CreateReviewLink>
      <CreateReviewLink href="">
        View All Reviews
      </CreateReviewLink>
    </ReviewSection>
  );
}



export default Yelp;
