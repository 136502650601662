import React, { useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {GoChevronLeft} from 'react-icons/go'

const MediaNavContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 10vw;
    height: 100%;
    background-color: white;
    display: grid;
    align-items: center;
    
    transition: 0.7s ease-in-out;
    padding: 20px;
    opacity: ${({ isOpen })=> (isOpen ? '100%': '0')};
    right: ${({ isOpen })=> (isOpen ? '0' : '-100%')};
    @media screen and (max-width: 900px){
        width: 40vw;
    }


`
const MediaNavList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    

`
const MediaNavLink = styled.a`
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    margin-top: 50px;
    width: 100%;
    :hover{
        font-size: 40px;
        color: #b0b0b0;
        transition: 0.3s ease-in-out;
    }

`
const CloseIcon = styled(FaTimes)`
    color: black;

`
const Icon = styled.div`
    position: absolute;
    top: 100px;
    left: -50px;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    outline: none;
`

const MediaLinkIcons = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
`
const MediaIcon = styled.a `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration:none;
    color: white;
        :hover{
            font-size: 25px;
            transition: .3s ease-in-out;
        }
    

`
const Text = styled.div`
    font-size: 12px;
    padding: 5px;
 

`

const Logo = styled.h2`
    position: absolute;
    top: 20px;
    left: 10px;
    display: block;
    background: rgb(121,9,9);
    background: linear-gradient(90deg, rgba(121,9,9,1) 40%, rgba(169, 169, 169) 100%);
    background-clip: text;
    color: transparent;

`

function MediaLinks({ isOpen, toggle }) {
  const [headerHeight] = useState(80); // Replace with your actual header/navbar height
  smoothscroll.polyfill();
  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const scrollPosition = section.offsetTop - headerHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
      toggle(); // Close the media navigation after clicking a link
    }
  };

  return (
    <>
      <MediaNavContainer isOpen={isOpen} onClick={toggle}>
        <Icon>
          <CloseIcon />
        </Icon>
        <MediaNavList>
          <MediaNavLink href="#about" >
            <div></div>
            About Us
          </MediaNavLink>
          <MediaNavLink href="#services" >
            <div></div>
            Services
          </MediaNavLink>
          <MediaNavLink href="https://support.reddawgtech.com" >
            <div></div>
            Contact
          </MediaNavLink>
        </MediaNavList>
        <MediaLinkIcons></MediaLinkIcons>
      </MediaNavContainer>
    </>
  );
}

export default MediaLinks
