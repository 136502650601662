import React from 'react';
import styled from 'styled-components';
import image from '../assests/theme.jpg';
import server from '../assests/server.jpg';
import logo from '../assests/logo.png';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: 400px;
  position: relative;
  scroll-snap-align: start;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const CallToAction = styled.div`
  position: absolute;
  top: 60px;
  left: 50px;
  color: white;
  padding: 20px;
  font-size: 2em;
  max-width: 500px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-shadow: 3px 3px 6px black;
  @media screen and (max-width: 500px) {
    font-size: 30px;
    top: 20px;
    left: 20px;
  }
  @media screen and (max-height: 500px){
    max-width: 1000px;
    top: 5px;
  }
`;

const ImgBG = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AboutTitle = styled.h3`
  padding-left: 50px;
  margin-top: 30px;
  margin-right: 20px;
  text-decoration: underline;
`;

const AboutParagraph = styled.p`
  padding: 50px;
`;

const Image = styled.img`
  // width: 100%;
  height: 50vh;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    object-position: left; /* Adjust this value based on your preference */
  }
`;

function About() {
  return (
    <Container id="about">
      <Image src={server} />
      <CallToAction>
        Empower your business with seamless IT solutions, exceptional support, and cutting-edge security
      </CallToAction>
      <AboutContainer>
        <AboutTitle>RedDawg Tech Team</AboutTitle>
        <AboutParagraph>
          Introducing Reddawg Tech, your trusted partner in server management
          solutions. As a budding Server Management Company, we're committed to
          delivering reliable services through our team of skilled Server
          Engineers. Our focus areas include Linux Server Management, Windows
          Server Management, Outsourced Web Hosting Support, and Cloud
          Infrastructure Management. While we may be starting out, our
          dedication to designing, installing, configuring, and maintaining
          servers ensures the optimal functioning and security of your business.
          At Reddawg Tech, we understand the importance of staying secure in the
          dynamic tech landscape, which is why we conduct regular security audits
          for our clients. Join us on this journey, and let Reddawg Tech be your
          partner in navigating the complexities of server management with a
          commitment to growth and excellence.
        </AboutParagraph>
      </AboutContainer>
    </Container>
  );
}

export default About;
