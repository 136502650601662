import React, { useState } from 'react';
import styled from 'styled-components';
import { FaServer, FaCloud, FaLock, FaCodeBranch } from 'react-icons/fa';
import Modal from 'react-modal';

const ProjectsContainer = styled.div`
  width: 100vw;
  margin-bottom: 50px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  width: 500px; /* Set a fixed width for the BoxContainer */
`;

const Box = styled.div`
  width: 100%;
  height: 400px; /* Set a fixed height for the Box */
  box-shadow: 15px 10px 5px grey;
  border: solid;
  border-width: thin;
  border-color: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Added to evenly distribute content vertically */
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: ${(props) => props.backgroundColor || 'lightcoral'};
  &:hover {
    background-color: darkred;
    color: white;
  }
  @media screen and (max-width: 1000px) {
    height: 300px;
  }
`;

const ProjectTitle = styled.h4`
  padding: 10px;
`;

const ProjectIcon = styled.div`
  font-size: 5em;
  padding: 30px;
`;

const SelectedProjectIcon = styled.div`
  font-size: 15vw;
  margin-left: 20vw;
`;

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    width: '60%',
    margin: 'auto',
    padding: '20px',
    borderRadius: '10px',
  },
};

function Projects() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setModalIsOpen(false);
  };

  const backgroundColors = ['#FFCCCC', '#FF9999', '#FF6666', '#FF3333']; // Add more shades as needed


  const projects = [
    {
      title: 'Server Management',
      icon: <FaServer />,
      description: `
        Our Server Management service goes beyond simple administration. We employ advanced features such as live monitoring, web monitoring, and security checks to ensure the security, maintenance, and optimal performance of your server.
        \n\n
        \n\n
        A comprehensive security audit and regular patching are integral to our services, offering protection from various vulnerabilities. Our proactive approach includes monitoring all server services, such as CPU, DISK, RAM, Mail Queue, FTP, DNS, and MySQL, ensuring proper uptime. In case of downtime or emergencies, our expert team takes swift action on your behalf.
        \n\n
        \n\n
        Our admins swiftly address issues without extensive research. Trained in Linux, Windows, Ubuntu, CentOS, Debian, and various other operating systems, our team ensures quick issue resolution to minimize downtime. Our cost-effective plans and dedicated support have made us the choice for thousands of server owners dealing with daily server issues.
      `,
    },
    {
      title: 'Network Security Monitoring',
      icon: <FaLock />,
      description: `
        Network Security Monitoring is crucial for safeguarding your digital infrastructure. Our advanced monitoring solutions detect and mitigate potential threats, ensuring the integrity and security of your network.
        \n\n
        \n\n
        Our services include continuous monitoring of network activities, identifying suspicious patterns, and implementing proactive measures to prevent security breaches. By leveraging cutting-edge technologies, we provide comprehensive network security solutions tailored to your business needs.
      `,
    },
    {
      title: 'Cloud Infrastructure Management',
      icon: <FaCloud />,
      description: `
        Cloud Infrastructure Management involves the setup, configuration, and ongoing optimization of cloud resources. Our services ensure a seamless and scalable cloud environment, allowing businesses to leverage the benefits of cloud computing.
        \n\n
        \n\n
        We specialize in configuring cloud infrastructure, optimizing resource allocation, and ensuring high availability. With our expertise, you can achieve cost-effective and efficient cloud operations tailored to your specific requirements.
      `,
    },
    {
      title: 'Datacenter Management',
      icon: <FaCodeBranch />,
      description: `
        Datacenter Management is a comprehensive approach to the planning, configuration, and ongoing support of datacenter operations. We ensure the reliability and performance of your datacenter, helping you meet the demands of your business operations.
        \n\n
        \n\n
        Our services include capacity planning, hardware optimization, and continuous monitoring to ensure the seamless operation of your datacenter. With our dedicated team, you can streamline datacenter management and focus on core business activities.
      `,
    },
  ];

  return (
    <ProjectsContainer id="services">
      {projects.map((project, index) => (
        <BoxContainer key={index}>
          <Box
            backgroundColor={backgroundColors[index % backgroundColors.length]}
            onClick={() => openModal(project)}
          >
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectIcon>{project.icon}</ProjectIcon>
          </Box>
        </BoxContainer>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Project Modal"
      >
        {selectedProject && (
          <>
            <h2>{selectedProject.title}</h2>
            <p>{selectedProject.description}</p>
            <SelectedProjectIcon>{selectedProject.icon}</SelectedProjectIcon>
            <button onClick={closeModal}>Close</button>
          </>
        )}
      </Modal>
    </ProjectsContainer>
  );
}

export default Projects;
