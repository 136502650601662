import React from 'react';
import styled from 'styled-components';
import { FaCogs, FaDatabase, FaServer, FaShieldAlt, FaCloud, FaHardHat, FaComments, FaUserShield, FaSync, FaGlobe, FaArrowUp } from 'react-icons/fa';

const WhatWeDoContainer = styled.div`
  width: 100vw;
  margin-bottom: 50px;
  padding: 20px;
  text-align: center;
`;

const WhatWeDoTitle = styled.h3`
  margin-bottom: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
`;

const Item = styled.div`
  text-align: center;
  transition: 0.2s ease-in-out;
  
`;

const ItemIcon = styled.div`
  font-size: 2em;
  margin-bottom: 10px;
`;

const ItemTitle = styled.h4`
  padding: 10px;
`;

const ItemDescription = styled.p`
  padding: 10px;
  text-align: center;
`;

const WhatWeDo = () => {
  const services = [
    {
      title: '24x7 Proactive Monitoring',
      icon: <FaCogs />,
      description: 'With our proactive 24×7 monitoring we make sure that your server and entire services are up 24×7.',
    },
    {
      title: 'Backup and Recovery',
      icon: <FaDatabase />,
      description: 'We configure remote backup for your server to ensure that your data is saved even when your entire data center is down.',
    },
    {
      title: 'Optimize Server',
      icon: <FaServer />,
      description: 'We optimize your WEB, DATABASE, MAIL and any server services for the best performance.',
    },
    {
      title: '24x7 Server Management',
      icon: <FaHardHat />,
      description: 'Our 24×7 SERVER MANAGEMENT TEAM IS HERE TO address any of the issues you arise.',
    },
    {
      title: 'Secure Server',
      icon: <FaShieldAlt />,
      description: 'We provide security monitoring for the server from DOS, DDOS, MALWARE, and any other type of vulnerability.',
    },
    {
      title: 'Server Setup',
      icon: <FaCloud />,
      description: 'We set up your Cloud/Virtual/Dedicated server from scratch.',
    },
    {
      title: 'Administration',
      icon: <FaUserShield />,
      description: 'Our various technology server experts visualize and plan flawless server maintenance strategies for you.',
    },
    {
      title: 'Regular Server Update',
      icon: <FaSync />,
      description: 'Regularly updating kernel and other packages to ensure security.',
    },
    {
      title: 'Server Upgrade',
      icon: <FaArrowUp />,
      description: 'Enhance your server performance and capabilities with our professional server upgrade services.',
    },
    {
      title: 'Custom Websites and Hosting',
      icon: <FaGlobe />, 
      description: 'We design and develop custom websites tailored to your needs and provide reliable hosting services for seamless online presence.',
    },
    
  ];

  return (
    <WhatWeDoContainer id="what-we-do">
      <WhatWeDoTitle>What We Do</WhatWeDoTitle>
      <GridContainer>
        {services.map((service, index) => (
          <Item key={index}>
            <ItemIcon>{service.icon}</ItemIcon>
            <ItemTitle>{service.title}</ItemTitle>
            <ItemDescription>{service.description}</ItemDescription>
          </Item>
        ))}
      </GridContainer>
    </WhatWeDoContainer>
  );
};

export default WhatWeDo;
